// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-page {
    padding: 20px;
  }
  
  .settings-container {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .settings-field {
    margin-bottom: 15px;
  }
  
  .settings-field label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .settings-field input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }
  
  .save-changes-btn {
    background-color: #2c3e50;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .delete-account-btn {
    background-color: #e74c3c;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/SettingsPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,yCAAyC;EAC3C;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB","sourcesContent":[".settings-page {\n    padding: 20px;\n  }\n  \n  .settings-container {\n    background-color: #fff;\n    padding: 30px;\n    border-radius: 8px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  }\n  \n  h2 {\n    font-size: 24px;\n    margin-bottom: 20px;\n  }\n  \n  h3 {\n    font-size: 20px;\n    margin-bottom: 10px;\n  }\n  \n  .settings-field {\n    margin-bottom: 15px;\n  }\n  \n  .settings-field label {\n    display: block;\n    font-weight: bold;\n    margin-bottom: 5px;\n  }\n  \n  .settings-field input {\n    width: 100%;\n    padding: 8px;\n    font-size: 16px;\n    border-radius: 4px;\n    border: 1px solid #ccc;\n    margin-bottom: 10px;\n  }\n  \n  .save-changes-btn {\n    background-color: #2c3e50;\n    color: #fff;\n    padding: 10px 15px;\n    border: none;\n    border-radius: 4px;\n    font-size: 16px;\n    cursor: pointer;\n    margin-right: 10px;\n  }\n  \n  .delete-account-btn {\n    background-color: #e74c3c;\n    color: #fff;\n    padding: 10px 15px;\n    border: none;\n    border-radius: 4px;\n    font-size: 16px;\n    cursor: pointer;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
