// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  /* position: fixed;
  top: 0;
  left: 0;
  width: 240px; Adjust width as needed
  height: 100vh;  */
  position: fixed;
  overflow: hidden;
  width: 240px;
  background-color: #233B69;
  color: white;
  height: 105vh;
  padding-top: 20px;

  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar li {
    padding: 15px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .sidebar li:hover {
    background-color: #1A2D50;
  }
  
  .sidebar li svg {
    margin-right: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/Sidebar.css"],"names":[],"mappings":"AAAA;EACE;;;;mBAIiB;EACjB,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,iBAAiB;;EAEjB;;EAEA;IACE,qBAAqB;IACrB,UAAU;EACZ;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":[".sidebar {\n  /* position: fixed;\n  top: 0;\n  left: 0;\n  width: 240px; Adjust width as needed\n  height: 100vh;  */\n  position: fixed;\n  overflow: hidden;\n  width: 240px;\n  background-color: #233B69;\n  color: white;\n  height: 105vh;\n  padding-top: 20px;\n\n  }\n  \n  .sidebar ul {\n    list-style-type: none;\n    padding: 0;\n  }\n  \n  .sidebar li {\n    padding: 15px 20px;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n  }\n  \n  .sidebar li:hover {\n    background-color: #1A2D50;\n  }\n  \n  .sidebar li svg {\n    margin-right: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
