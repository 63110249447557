// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.patient-card {
    width: 250px;
    background-color: #FFFFFF;
    border: 1px solid #E6E8F0;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 15px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .patient-card img.patient-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
  }
  
  .patient-card h3 {
    font-size: 18px;
    color: #303B5B;
    margin-bottom: 5px;
  }
  
  .patient-card p {
    width: 250px;
    font-size: 14px;
    color: #8C8FA4;
    margin: 8px 0;
  }
  
  .patient-details-btn {
    background-color: #2F3D7E;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 15px 40px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
  }
  
  .patient-details-btn:hover {
    background-color: #374894;
  }
  
  .patient-card p.weight,
  .patient-card p.blood-pressure,
  .patient-card p.blood-glucose {
    font-weight: 500;
    color: #303B5B;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/PatientCard.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;IACzB,yBAAyB;IACzB,mBAAmB;IACnB,wCAAwC;IACxC,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,kCAAkC;IAClC,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,eAAe;IACf,cAAc;IACd,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,iCAAiC;IACjC,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;;;IAGE,gBAAgB;IAChB,cAAc;EAChB","sourcesContent":[".patient-card {\n    width: 250px;\n    background-color: #FFFFFF;\n    border: 1px solid #E6E8F0;\n    border-radius: 12px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    padding: 20px;\n    margin: 15px;\n    text-align: center;\n    font-family: 'Poppins', sans-serif;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .patient-card img.patient-image {\n    width: 60px;\n    height: 60px;\n    border-radius: 50%;\n    object-fit: cover;\n    margin-bottom: 15px;\n  }\n  \n  .patient-card h3 {\n    font-size: 18px;\n    color: #303B5B;\n    margin-bottom: 5px;\n  }\n  \n  .patient-card p {\n    width: 250px;\n    font-size: 14px;\n    color: #8C8FA4;\n    margin: 8px 0;\n  }\n  \n  .patient-details-btn {\n    background-color: #2F3D7E;\n    color: white;\n    border: none;\n    border-radius: 8px;\n    padding: 15px 40px;\n    font-size: 14px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n    margin-top: 10px;\n  }\n  \n  .patient-details-btn:hover {\n    background-color: #374894;\n  }\n  \n  .patient-card p.weight,\n  .patient-card p.blood-pressure,\n  .patient-card p.blood-glucose {\n    font-weight: 500;\n    color: #303B5B;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
