// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.start-appointment-btn {
  margin-left: auto; /* Align the button to the right */
  background-color: #2F3D7E !important; 
  color: white !important;
  border-radius: 5px;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
}

.start-appointment-btn:hover {
  background-color: #0056b3;
}

.e-btn.e-flat.e-primary{
    color:#2F3D7E;
}`, "",{"version":3,"sources":["webpack://./src/styles/Appointment.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB,EAAE,kCAAkC;EACrD,oCAAoC;EACpC,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".start-appointment-btn {\n  margin-left: auto; /* Align the button to the right */\n  background-color: #2F3D7E !important; \n  color: white !important;\n  border-radius: 5px;\n  padding: 10px 15px;\n  border: none;\n  cursor: pointer;\n}\n\n.start-appointment-btn:hover {\n  background-color: #0056b3;\n}\n\n.e-btn.e-flat.e-primary{\n    color:#2F3D7E;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
