// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topbar {
  display: flex;
  align-items: center;
  background-color: #f7f9fc;
  padding: 20px 20px;
  z-index: 1000;
  top: 0;
  width: 100%; /* Adjust to not overlap sidebar */
}

.topbarWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Ensure the wrapper takes full width */
}

.topLeft {
  display: flex;
  align-items: center;
}

.topRight {
  display: flex;
  align-items: center;
  position: relative;
}

.title {
  font-size: 20px;
  font-weight: 700;
  color: #333;
}

.profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #eee;
}

.profile-modal {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1001;
}

.profile-modal button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 1rem;
  text-align: left;
  width: 100%;
}

.profile-modal button:hover {
  background-color: #f1f1f1;
}
`, "",{"version":3,"sources":["webpack://./src/styles/TopBar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,MAAM;EACN,WAAW,EAAE,kCAAkC;AACjD;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW,EAAE,wCAAwC;AACvD;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,uBAAuB;EACvB,kBAAkB;EAClB,0CAA0C;EAC1C,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".topbar {\n  display: flex;\n  align-items: center;\n  background-color: #f7f9fc;\n  padding: 20px 20px;\n  z-index: 1000;\n  top: 0;\n  width: 100%; /* Adjust to not overlap sidebar */\n}\n\n.topbarWrapper {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%; /* Ensure the wrapper takes full width */\n}\n\n.topLeft {\n  display: flex;\n  align-items: center;\n}\n\n.topRight {\n  display: flex;\n  align-items: center;\n  position: relative;\n}\n\n.title {\n  font-size: 20px;\n  font-weight: 700;\n  color: #333;\n}\n\n.profile-picture {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  cursor: pointer;\n  border: 2px solid #eee;\n}\n\n.profile-modal {\n  position: absolute;\n  top: 50px;\n  right: 0;\n  background-color: white;\n  border-radius: 8px;\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n  padding: 10px;\n  display: flex;\n  flex-direction: column;\n  z-index: 1001;\n}\n\n.profile-modal button {\n  background: none;\n  border: none;\n  padding: 8px;\n  cursor: pointer;\n  font-size: 1rem;\n  text-align: left;\n  width: 100%;\n}\n\n.profile-modal button:hover {\n  background-color: #f1f1f1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
